/* 
File: work_plan.js
Description: provides main routes for the WorkPlan, budgeting and HDM forms, component of the routes.js
*/
const GenerateTreatment = () => import('@/pages/Planning/GenerateTreatment/GenerateTreatment.vue')
const Plans = () => import('@/pages/Planning/PreparePlan/PreparePlan.vue')
const TitleList = () => import('@/pages/Planning/TitleList/TitleList.vue')
/*
const MaintenancePlan_all = () => import('@/pages/WorkPlan3/MaintenancePlan_all.vue')
*/
const MaintenancePlan_single = () => import('@/pages/Planning/TitleList/MaintenancePlan_single.vue')

const WorkPlanMenu = [
  {
    path: 'generate_treatment',
    name: 'generate_treatment',
    component: GenerateTreatment
  },
  {
    path: 'prepare_plan',
    name: 'prepare_plan',
    component: Plans
  },
  {
    path: 'title_list',
    name: 'title_List',
    component: TitleList
  },
/*
  {
    path: 'budget/show_all',
    name: 'budget_show_all',
    component: MaintenancePlan_all
  },
*/
  {
    path: 'budget/show',
    name: 'budget_show_populated',
    component: MaintenancePlan_single
  },
]

export default WorkPlanMenu