import Api from '@/api/planning'

export default {
  state: {
    list: [],
    dropdownList: []
  },
  actions: {
    async LOAD_PLAN_LIST({ commit }, payload) {
      const lang = global.localStorage["mps-locale"]
      const { region_id, year, forDropdown = false } = payload
      try {
        const { status, data } = await Api.load_plan_list(lang, region_id, year)
        if (status === 200) {
          data.sort((a, b) => {
            let res = a.region_description.localeCompare(b.region_description, undefined, { sensitivity: 'base' })
            if (res == 0) { res = a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) }
            return res
          })
          commit(forDropdown ? 'SET_PLAN_DROPDOWN_LIST' : 'SET_PLAN_LIST', data)
        } else {
          throw `Load plan list status: ${status}`
        }
      } catch (err) {
        throw `Load Plan list: ${err}`
      } finally {
      }
    },

    async LOAD_PLAN_BY_ID({ commit }, id) {
      const lang = global.localStorage["mps-locale"]
      try {
        const { status, data } = await Api.load_plan_by_id(lang, id)
        if (status === 200) {
          return data
        } else {
          throw 'Error LOAD_PLAN_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      }
    },

    async ADD_PLAN({ commit }, payload) {
      //const { plan_name, region_id } = payload
      try {
        const { status, data } = await Api.add_plan(payload)
        if (status === 201) {
          return data.id  //'success'
        } else {
          throw status
        }
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return 'exists'
        } else if (err.response) {
          throw new Error(err.response.data.message || 'Unknown error')
        } else {
          console.error('Unexpected error:', err)
          throw new Error('Network or client-side error')
        }
      }
    },

    async UPD_PLAN({ commit }, payload) {
      try {
        const { id, plan } = payload
        const { status } = await Api.upd_plan(id, plan)
        if (status === 201) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async APPROVE_PLAN({ commit }, id) {   //payload) {
      try {
        //const { id, plan } = payload
        const { status } = await Api.approve_plan(id)   //upd_plan(id, plan)
        if (status === 201) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async CANCEL_PLAN({ commit }, id) {
      try {
        const { status } = await Api.cancel_plan(id)
        if (status === 201) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async DELETE_PLAN({ commit }, id) {
      try {
        const { status } = await Api.delete_plan(id)
        if (status === 200) {
          return 'success'
        } else {
          throw status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'failed'
      }
    },

    async PLAN_CHANGE_LOCK_STATUS({ }, id) {
      try {
        const res = await Api.plan_change_lock_status(id)
        if (res.status === 200) {
          return Boolean(res.data.locked_at)
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'filed'
      }
    }
    // async DEL_PLAN({commit}, id) {
    //   try {
    //     commit('SET_USER_OPER', true)
    //     const res = await Api.del_user(id)
    //     if (res.status === 200) {
    //       commit('DEL_USER_FROM_LIST', id)
    //     } else {
    //       throw res.status
    //     }
    //   } catch (err) {
    //     throw `${err.response.data.message}`
    //   } finally {
    //     commit('SET_USER_OPER', false)
    //   }
    // }
  },

  mutations: {
    SET_PLAN_LIST(state, list) {
      state.list = [...list]
    },
    SET_PLAN_DROPDOWN_LIST(state, list) {
      state.dropdownList = [...list]
    },
    // DEL_PLAN_FROM_LIST(state, id) {
    //   let ind = state.list.findIndex((row) => row.id === id)
    //   if (ind >= 0) {
    //     state.list.splice(ind, 1)
    //   }
    // },
  },

  getters: {
    plansNotApproved: (state) => (fk_region, year) => {
      return state.dropdownList.filter(el =>
        !el.approved_by_user_id &&            // Не утверждено пользователем
        (!fk_region || el.fk_region === fk_region) &&  // Проверка на регион (если передан)
        (!year || el.year === year)           // Проверка на год (если передан)
      )
        // Оставляем только нужные поля
        .map(el => ({ id: el.id, name: el.name }))
        // Сортировка по названию
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },

    planApproved: (state) => (id) => {
      return !id ? false : Boolean(state.list.find((el) => el.id == id)?.approved_by_user_id)
    },

    yearsInPlanList: (state) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year !== null) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ year }));

      uniqueYears.unshift({ year: '' })   // Add null value to the beginnig
      return uniqueYears
    },

    planListByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(row => row.year === year)
    },
  }
}